<template>
  <form class="form">
		<slot></slot>
	</form>
</template>

<script>
export default {
	data(){
		return{
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.form{
	width:100%;
	grid-gap: var(--form-gutters);
	display:grid;
	margin: var(--form-mobile-margins);
	@include breakpoint(sm){
		grid-template-columns:repeat(12, 1fr);
		grid-template-rows: auto;
		margin: var(--form-margins);
	}
}
.form--col-auto{
	@include breakpoint(sm){
		grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
	}
}
.form.form--col-1{
  .form__container{
    grid-column-end: span 12;
		@include breakpoint(sm){
			grid-column-end: span 12;
		}
  }
}
.form.form--col-2{
  .form__container{
    grid-column-end: span 12;
		@include breakpoint(sm){
			grid-column-end: span 6;
		}
  }
}
.form.form--col-3{
  .form__container{
    grid-column-end: span 12;
		@include breakpoint(sm){
			grid-column-end: span 4;
		}
  }
}
.form.form--col-4{
  .form__container{
    grid-column-end: span 12;
		@include breakpoint(sm){
			grid-column-end: span 3;
		}
  }
}
</style>

<!-- Used in : AR -->